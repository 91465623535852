export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [~3],
		"/(app)/akcii": [~5,[2]],
		"/(app)/akcii/[promotion]": [~6,[2]],
		"/(app)/ceny": [~7,[2]],
		"/(app)/faq": [~8,[2]],
		"/(app)/faq/[faq]": [~9,[2]],
		"/(app)/gorod-[city]": [~10,[2]],
		"/(app)/gorod-[city]/akcii": [~12,[2]],
		"/(app)/gorod-[city]/akcii/[promotion]": [~13,[2]],
		"/(app)/gorod-[city]/ceny": [~14,[2]],
		"/(app)/gorod-[city]/faq": [~15,[2]],
		"/(app)/gorod-[city]/faq/[faq]": [~16,[2]],
		"/(app)/gorod-[city]/karta": [~17,[2]],
		"/(app)/gorod-[city]/kategoriya-[catalog]": [~18,[2]],
		"/(app)/gorod-[city]/kompaniya": [~19,[2]],
		"/(app)/gorod-[city]/kontakty": [~20,[2]],
		"/(app)/gorod-[city]/media-center": [~21,[2]],
		"/(app)/gorod-[city]/media-center/[media]": [~22,[2]],
		"/(app)/gorod-[city]/otzyvy": [~23,[2]],
		"/(app)/gorod-[city]/partneram": [~24,[2]],
		"/(app)/gorod-[city]/partnery": [~25,[2]],
		"/(app)/gorod-[city]/partnery/[partner]-project": [~27,[2]],
		"/(app)/gorod-[city]/partnery/[partner]": [~26,[2]],
		"/(app)/gorod-[city]/poisk": [~28,[2]],
		"/(app)/gorod-[city]/rabota": [~29,[2]],
		"/(app)/gorod-[city]/usluga-[service]": [~30,[2]],
		"/(app)/gorod-[city]/[page]": [~11,[2]],
		"/(app)/karta": [~31,[2]],
		"/(app)/kategoriya-[catalog]": [~32,[2]],
		"/(app)/kompaniya": [~33,[2]],
		"/(app)/kontakty": [~34,[2]],
		"/(app)/media-center": [~35,[2]],
		"/(app)/media-center/[media]": [~36,[2]],
		"/(app)/otzyvy": [~37,[2]],
		"/(app)/partneram": [~38,[2]],
		"/(app)/partnery": [~39,[2]],
		"/(app)/partnery/[partner]-project": [~41,[2]],
		"/(app)/partnery/[partner]": [~40,[2]],
		"/(app)/poisk": [~42,[2]],
		"/(app)/rabota": [~43,[2]],
		"/(app)/usluga-[service]": [~44,[2]],
		"/(app)/[page]": [~4,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';